import React, { FC, useEffect } from 'react'
import { useState } from 'react'
import { useSpring, useTransition, animated } from 'react-spring'
// import { Link } from 'react-router-dom'
import Tag from '../Tag/Tag'
import './project.scss'

interface ProjectProp {
  title: string
  img: string[]
  about: string
  tags: string[]
  content: string
  report: string
}
const Project: FC<ProjectProp> = ({ title, img, about, tags, content, report }) => {
  const [showMod, setShowMod] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)

  const fadeIn = useSpring({
    config: { duration: 100 },
    // width: hovered ? '100%' : '0%',
    opacity: hovered ? 0.9 : 0,
    // from: { width: '0%' },
  })

  const handleKeyPress = (e: any) => {
    e.key === 'Escape' && setShowMod(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      handleKeyPress(e)
    })

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  // useEffect(() => {
  // 	s.style.overflow = showMod ? 'hidden' : 'auto'
  // }, [showMod])

  useEffect(() => {
    let s = document.getElementsByTagName('*')[0] as HTMLElement
    return () => {
      s.style.overflow = !showMod ? 'hidden' : 'auto'
    }
  }, [showMod])

  return (
    <div
      id='project'
      onClick={() => {
        setShowMod(true)
        setHovered(false)
      }}
      onMouseOver={() => {
        !showMod && setHovered(true)
      }}
      onMouseLeave={() => setHovered(false)}
    >
      <div className='projectTitle'>
        <span>{title}</span>
      </div>

      {hovered && (
        <animated.div className='hoverOverlay' style={{ opacity: fadeIn.opacity }}>
          {' '}
          <span>More about {title}</span>{' '}
        </animated.div>
      )}

      <div className='projectContent'>
        <div className={title === 'Monte Carlo Ray Tracer' ? 'projectImgContainer mc' : 'projectImgContainer'}>
          {/* <div className='projectImgContainer'> */}
          <img className='imgSmall' src={img[0]} alt='' />
        </div>
        <div className='aboutProject'> {about}</div>
        <div className='tags'>{tags[0] !== '' && tags.map((item: string, index: number) => <Tag key={index} title={item} />)}</div>
      </div>

      {showMod ? (
        <animated.div className='modMain'>
          <div className='modChild'>
            <button
              className='close'
              onClick={(e) => {
                setShowMod(false)
                e.stopPropagation()
              }}
            >
              <div className='closeContainer'>
                <div className='leftRight'></div>
                <div className='rightLeft'></div>
              </div>
            </button>
            <div className='projectTitle mod'>
              <span>{title}</span>
            </div>
            <div className='projectContent'>
              <div className='projectImgContainer mod'>
                <img className='largeImg' src={img[0]} />
              </div>
              <div className='aboutProject mod'>
                <p style={{ marginBottom: '20px' }}>{content}</p>
                {report !== '' && (
                  <a href={report} download target='_blank'>
                    Link to full report
                  </a>
                )}
                {img.length > 1 &&
                  img.map((item: string, index: number) => {
                    return (
                      <div key={index} className='projectImgContainer mod'>
                        <img style={{ marginTop: '10px' }} className='largeImg' src={item} alt='' />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </animated.div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Project
