import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import './contact.scss'

const Contact = (props: any) => {
  const contactRef = props.contactRef
  return (
    <div id='contactWrapper' ref={contactRef}>
      <div className='headlineWrapper'>
        {' '}
        <span className='chapter'> Contact</span>
      </div>
      <div id='content'>
        {' '}
        <div id='icons'>
          <a className='link' href='https://github.com/OzcarOlsson' target='_blank'>
            <FontAwesomeIcon className='contactIcons' icon={faGithub} />{' '}
          </a>
          <a className='link' href='https://www.linkedin.com/in/olsson-oscar' target='_blank'>
            <FontAwesomeIcon className='contactIcons' icon={faLinkedin} />
          </a>

          <a className='link' href='mailto:oscarohlsson4@gmail.com'>
            <FontAwesomeIcon className='contactIcons' icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact
