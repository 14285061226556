import React from 'react'
import { useSpring, animated } from 'react-spring'

import './header.scss'

const Header = (props: any) => {
  const fromLeft = useSpring({
    // config: { duration: 1000 },
    transform: 'translate3d(0, 0 , 0)',
    from: { transform: 'translate3d(-1000px, 0 , 0)' },
  })

  return (
    <div id='headerWrap'>
      {/* <span style={{ fontSize: 18 }}>M.Sc Student in Media technology and Engineering.</span> */}
      <animated.div
        style={{
          height: '70%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          transform: fromLeft.transform,
        }}
      >
        <div id='headerTitle'>
          <span id='headerText'>OSCAR OLSSON</span>
          <span id='headerDescription'>M.Sc Student in Media technology and Engineering.</span>
        </div>
      </animated.div>
      <div id='menu'>
        <span
          className='menuItem'
          onClick={() => {
            props.setSelected(props.refs[0])
          }}
        >
          About
        </span>
        <span
          className='menuItem'
          onClick={() => {
            props.setSelected(props.refs[1])
          }}
        >
          Portfolio
        </span>
        {/* <span
          className='menuItem'
          onClick={() => {
            props.setSelected(props.refs[1])
          }}
        >
          Resume
        </span> */}
        <span
          className='menuItem'
          onClick={() => {
            props.setSelected(props.refs[2])
          }}
        >
          Contact
        </span>
      </div>
    </div>
  )
}

export default Header
