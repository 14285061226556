import './global.scss'
import React, { useEffect, useState, useRef } from 'react'

import Portfolio from './components/Portfolio/Portfolio'
import About from './components/About/About'
import Beamer from './components/Beamer/Beamer'
import Contact from './components/Contact/Contact'

const App = () => {
  const aboutRef = useRef<HTMLDivElement>(null)
  const portfolioRef = useRef<HTMLDivElement>(null)
  const contactRef = useRef<HTMLDivElement>(null)
  const homeRef = useRef<HTMLDivElement>(null)
  const [selected, setSelected] = useState<any>(null)

  const refs = [aboutRef, portfolioRef, contactRef]

  useEffect(() => {
    selected?.current.scrollIntoView({ behavior: 'smooth' })
  }, [selected])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setSelected(null)
    })

    return window.removeEventListener('scroll', () => {})
  }, [])

  return (
    <div id='App' ref={homeRef}>
      <div id='siteWrapper'>
        <Beamer refs={refs} selected={selected} setSelected={setSelected} />
      </div>
      <About aboutRef={aboutRef} portfolioRef={portfolioRef} contactRef={contactRef} setSelected={setSelected} />

      <Portfolio portfolioRef={portfolioRef} />
      <Contact contactRef={contactRef} />
    </div>
  )
}

export default App
