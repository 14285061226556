import React from 'react'
import './about.scss'
import '../Portfolio/portfolio.scss'

//TODO props interface
const About = (props: any) => {
  const aboutRef = props.aboutRef
  const contactRef = props.contactRef
  return (
    <div id='aboutWrapper' ref={aboutRef}>
      <div className='headlineWrapper'>
        <span className='chapter'> About</span>
      </div>
      <div id='content'>
        <span style={{ fontSize: '20px', marginBottom: '10px' }}>
          Hello world<span style={{ color: '#d28307' }}>!</span>
        </span>
        <span style={{ marginBottom: '10px' }}>
          I am Oscar, a 28-year old engineering student in Norrköping, Sweden. I am currently studying for my degree in Master of Science in
          Media Technology and Engineering. Right now I'm in my fourth year, taking master courses to extend my knowledge in the field of
          Computer Science and Media Technology.
        </span>
        <span style={{ marginBottom: '10px' }}>
          I'm mainly focused on full stack web development, 3D Graphics and software development. I'm interested in all types of programming
          and always tries to extend my knowledge into different fields of development, as well as diving deeper into some of the fields.
          Beside my studies I work as a Student IT-consultant at Unitalent.
        </span>
        <span style={{ marginBottom: '10px' }}>
          <span style={{ color: '#d28307', cursor: 'pointer' }} onClick={() => props.setSelected(props.portfolioRef)}>
            Checkout
          </span>{' '}
          some of my work below, that consists of both university projects, own projects and work related projects. Feel free to{' '}
          <span style={{ color: '#d28307', cursor: 'pointer' }} onClick={() => props.setSelected(contactRef)}>
            contact
          </span>{' '}
          me if you want to hear more about me and what I can do!
        </span>
      </div>
    </div>
  )
}

export default About
