import React from 'react'
import Project from '../Project/Project'

import './portfolio.scss'
import { synthProject, golfProject, monteCarlo, FLINCK, eye, nextgen } from '../../projectContents'

interface Pro {
  title: string
  img: string
  about: string
  tags: string[]
  content: string
  report: string
}

const Portfolio = (props: any) => {
  const portfolioRef = props.portfolioRef

  return (
    <div ref={portfolioRef} id='portfolioWrapper'>
      <div className='headlineWrapper'>
        <span className='chapter'> Portfolio</span>
      </div>
      <div id='projects'>
        <Project
          title={monteCarlo.title}
          img={monteCarlo.img}
          about={monteCarlo.about}
          tags={monteCarlo.tags}
          content={monteCarlo.content}
          report={monteCarlo.report}
        />
        <Project
          title={nextgen.title}
          img={nextgen.img}
          about={nextgen.about}
          tags={nextgen.tags}
          content={nextgen.content}
          report={nextgen.report}
        />

        <Project
          title={golfProject.title}
          img={golfProject.img}
          about={golfProject.about}
          tags={golfProject.tags}
          content={golfProject.content}
          report={golfProject.report}
        />
        <Project
          title={synthProject.title}
          img={synthProject.img}
          about={synthProject.about}
          tags={synthProject.tags}
          content={synthProject.content}
          report={synthProject.report}
        />
        {/* <Project
          title={FLINCK.title}
          img={FLINCK.img}
          about={FLINCK.about}
          tags={FLINCK.tags}
          content={FLINCK.content}
          report={FLINCK.report}
        />

        <Project title={eye.title} img={eye.img} about={eye.about} tags={eye.tags} content={eye.content} report={eye.report} /> */}
      </div>
    </div>
  )
}

export default Portfolio
