import React from 'react'
import { useSpring, animated } from 'react-spring'
import './beamer.scss'
import Header from '../Header/Header'

const Beamer = (props: any) => {
  const fromRight = useSpring({
    // config: { duration: 1000 },
    opacity: 1,
    transform: 'translate3d(0, 0 , 0)',
    from: { opacity: 0, transform: 'translate3d(1000px, 0 , 0)' },
  })

  const fromLeft = useSpring({
    // config: { duration: 1000 },
    transform: 'translate3d(0, 0 , 0)',
    from: { transform: 'translate3d(-1000px, 0 , 0)' },
  })

  const handleArrowClick = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div id='beamer'>
      <Header refs={props.refs} selected={props.selected} setSelected={props.setSelected} />
      <div id='box'> </div>
      <div id='container'>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <animated.span style={{ transform: fromLeft.transform }}>Computer Science</animated.span>
          <animated.span className='dot' style={{ color: '#d28307', marginLeft: '-10px', transform: fromRight.transform }}>
            .
          </animated.span>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <animated.span style={{ transform: fromLeft.transform }}>Web development</animated.span>
          <animated.span className='dot' style={{ color: '#d28307', marginLeft: '-10px', transform: fromRight.transform }}>
            .
          </animated.span>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <animated.span style={{ transform: fromLeft.transform }}>3D Graphics</animated.span>
          <animated.span className='dot' style={{ color: '#d28307', marginLeft: '-10px', transform: fromRight.transform }}>
            .
          </animated.span>
        </div>
        {/* <span style={{ fontSize: 18 }}>M.Sc Student in Media technology and Engineering.</span> */}
      </div>
      <div id='arrowDown'>
        <div id='arrowTextWrapper' onClick={() => handleArrowClick(props.refs[0])}>
          <span style={{ color: '#d28307' }}>Click!</span>
          <div id='arrow'></div>
        </div>
        <div id='rightCorner'></div>
      </div>
    </div>
  )
}

export default Beamer
