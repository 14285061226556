import synth from './images/mySynth.png' 
import mc from './images/100spp.png'
import golf from "./images/golf.png"
import nextgen1 from "./images/nextgen/new_index.png"
import nextgen2 from "./images/nextgen/new_products.png"
import nextgen3 from "./images/nextgen/afterSearch.png"
import nextgen4 from "./images/nextgen/new_product.png"

// import rp from './images/kladpriskoll_report.pdf'

const synthProject =  {
	title: "Synthesizer",
	img: [synth],
	about: "A web-based Synthesizer written in Javascript, with the use of Web Audio API. ",
	tags: ["JavaScript"],
	content: "",
	report: ""
}

const golfProject = {
	title: "Golf Simulation",
	img: [golf],
	about: "A simulation of a golfball flight in THREE.js",
	tags: ["JavaScript", "three.js"],
	content: "",
	report: ""
}

const monteCarlo = {
	title: "Monte Carlo Ray Tracer",
	img: [mc],
	about: "A  Monte Carlo Ray Tracer that renders still-images for 3D-scenes using the Monte Carlo integration",
	tags: ["C++"],
	content: `
	A Monte Carlo Ray tracer that renders still images of 3D scenes using the Monte Carlo integration algorithm.
	The ray tracer calculates intersections, direct lighting, indirect lighting and colorbleeding, diffuse materials, perfectly reflecting materials and transparent materials to render the scene. The tracer uses supersampling and ray-randomization. The rendering process uses simple parallelization with openMP.
	The Ray Tracer is written from scratch in C++. There are different objects in the scene with different material properties. The sphere in the top left corner has the material properties of a perfect reflector (mirror). 
	The sphere in the bottom left corner is a transparent object with a refraction index of 1.5 (glass). The two objects in the right side of the image is of Lambertian properties and also the surrounding walls (except for the mirror in the back). 
	There are some visible color bleeding effect in the image, especially on the sphere in the bottom right corner which is a desirable effect when trying to render photo-realistic images.
	`,
	report: "https://github.com/OzcarOlsson/monte-carlo-ray-tracer/blob/master/report.pdf",
	repo: "https://github.com/OzcarOlsson/monte-carlo-ray-tracer",
}

const FLINCK = {
	title: "FLINCK Technologies",
	img: [""],
	about: "FLINCK",
	tags: ["React", "TypeScript", "three.js", "GraphQL"],
	content: "",
	report: ""
}

const eye = {
	title: "Tournament Generator",
	img: [""],
	about: "Web-based tournament generator with login system, groups, brackets and game schedule.",
	tags: ["React"],
	content: "",
	report: ""
}

const nextgen = {
	title: "Bachelor's Degree web project",
	img: [nextgen1, nextgen2, nextgen3, nextgen4],
	about: "A web application for price comparison of clothes and accessories. The data is collected through webscraping of different retailers websites.",
	tags: ["Next.js", "React", "TypeScript"],
	content: `During my third year at the university I was part of a full stack developer team of seven people which together developed a web application 'Klädpriskollen' as our Bachelor's project. The developer team was divided into two subteams - taking care of the 
	front end and back end respectively. I was part of the front end team were we 
	created the website for the application and the REST-api. In the application, users can search for products and
	compare the price between different retailers. Functionality for filtering and sorting
	the results from the database was also implemented. 
	
	The data was collected with an automated web scraping service developed in Python
	by the back end team. The data was then stored in a MySQL-database. With the REST-api
	the data could then be fetched and presented for the users in the application.
	
	Here are some screenshots from the application: `,
	report: "",
}


// Oscar Olsson.me
// SciVis?


// infovis 1 + 2

// 

export {synthProject, golfProject, monteCarlo, FLINCK, eye, nextgen};	