import React, { FC } from 'react'

import './tag.scss'

interface TagProp {
  title: string
  // color: string
}

const Tag: FC<TagProp> = ({ title }) => {
  return (
    <div id='tag'>
      <span>{title}</span>
    </div>
  )
}

export default Tag
